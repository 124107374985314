import React from 'react';
import styled, { css } from 'styled-components';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import orbits3 from '../../assets/images/orbits3.svg';
import {
  BaseHeader3,
  GradientTitle,
  TextMedium,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import {
  forScreenAtMost800pxWide,
  forScreenAtMost1000pxWide,
} from '../../styles/mediaQueries';
import accentArrow from '../../assets/images/accentArrow.svg';
import funds from '../../assets/images/fundusze_belka.svg';

const GrantsSection = (): JSX.Element => (
  <Wrapper>
    <MainContentColumnWrapper>
      <InnerWrapper>
        <Background>
          <Header>Fundusze Unijne</Header>
        </Background>
      </InnerWrapper>
      <MainWrapper>
        <SidebarWrapper>
          <Upheader $color="delicateAccented">CONTENT</Upheader>
          <Paragraph>iSEAT</Paragraph>
        </SidebarWrapper>
        <ContentWrapper>
          <Funds />
          <ContentTitle>
            Narodowe Centrum Badań i Rozwoju, Inteligentny Rozwój 2014-2020
          </ContentTitle>

          <ContentText>
            <strong>Tytuł projektu:</strong> iSEAT - platforma oparta o sztuczną
            inteligencję, umożliwiająca przewidywanie właściwości biofizycznych
            przeciwciał terapeutycznych
            {'\n\n'}
            <strong>Nr umowy o dofinansowanie:</strong>{' '}
            POIR.01.01.01-00-0962/21-00
            {'\n\n'}W ramach projektu zostaną przeprowadzone prace
            badawczo-rozwojowe, których celem jest opracowanie bioinformatycznej
            metody umożliwiającej przeprowadzanie wirtualnych eksperymentów w
            zakresie charakteryzacji właściwości biofizycznych przeciwciał.
            {'\n\n'}
            Efektem realizacji projektu będzie produkt, platforma
            bioinformatyczna, oparta na mechanizmach sztucznej inteligencji,
            która pozwoli koncernom farmaceutycznym osiągnąć realne i wymierne
            korzyści – a więc skrócić czas potrzebny na tradycyjne eksperymenty,
            w dużej mierze wyeliminować koszty związane z długotrwałymi
            eksperymentami a także osiągnąć wysoką dokładność przy użyciu metody
            bioinformatycznej, która do tej pory osiągalna była jedynie dla
            laboratoryjnych eksperymentów.
            {'\n\n'}
            Projekt współfinansowany przez Unię Europejską.
            {'\n\n'}
            <strong>Wartość projektu:</strong> 9 056 300,50 zł
            {'\n'}
            <strong>Wartość dofinansowania:</strong> 7 108 608,37 zł
          </ContentText>
          <Funds />
        </ContentWrapper>
      </MainWrapper>
    </MainContentColumnWrapper>
  </Wrapper>
);

const Funds = styled.div`
  min-height: 63px;
  width: 100%;
  margin: 50px 0;
  background: url('${funds}');
  background-size: contain;
  background-repeat: no-repeat;

  &:first-child {
    margin: 0 0 50px 0;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  ${forScreenAtMost1000pxWide(css`
    flex-direction: column;
  `)}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Wrapper = styled.div`
  ${forScreenAtMost800pxWide(css`
    margin-bottom: 300px;
  `)}
  ${forScreenAtMost1000pxWide(css`
    overflow: hidden;
  `)}
  margin-bottom: 200px;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 240px 0 0 0;

  ${forScreenAtMost1000pxWide(css`
    flex-direction: column;
    gap: 0px;
    padding: 134px 0 0 0;
  `)}
`;

const Background = styled.div`
  position: relative;

  :before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -400px;
    left: 690px;
    width: 916px;
    height: 916px;
    background: url('${orbits3}');
    background-size: cover;
  }

  ${forScreenAtMost1000pxWide(css`
    :before {
      top: -150px;
      left: 460px;
      width: 453px;
      height: 453px;
    }
  `)}
`;

const Header = styled(GradientTitle).attrs({ $gradient: 'primary' })`
  max-width: 358px;
  margin-bottom: 75px;
`;

const ContentTitle = styled(BaseHeader3)`
  color: #000;
  margin: 0 0 20px 0;
`;

const ContentText = styled(TextMedium).attrs({
  $color: 'delicateAccented',
})`
  white-space: pre-wrap;
`;

const Paragraph = styled(TextMedium).attrs({ $color: 'tertiaryStronger' })`
  margin: 34px 0 0 15px;
  max-width: 300px;
  position: relative;
  font-weight: bold;

  :before {
    content: '';
    position: absolute;
    top: 9px;
    left: -40px;
    width: 27px;
    height: 7px;
    background: url('${accentArrow}') no-repeat;
  }

  ${forScreenAtMost1000pxWide(css`
    margin-top: 22px;
    margin-bottom: 40px;
    margin-left: 0;

    :before {
      display: none;
    }
  `)};
`;

export default GrantsSection;
