import React from 'react';
import { FooterBoxContent } from '../components/layout/Footer';
import Layout from '../components/layout/Layout';
import GrantsSection from '../pages-components/grants/GrantsSection';

const IndexPage = (): JSX.Element => {
  return (
    <Layout
      backgroundColor="secondary"
      footerBoxContent={FooterBoxContent.ContactInvitation}
      title="Grants"
    >
      <GrantsSection />
    </Layout>
  );
};

export default IndexPage;
